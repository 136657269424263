import React, { Component } from 'react';
import './App.css';

// Logos
import quoter from './images/quoter.png'

// Material ui
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class App extends Component {
  constructor() {
    super()
    this.state = {
      logoHeight: 30
    }

  }
  routeTo(link) {
    console.log("The link: ", link)
    window.open(link); //This will open Google in a new 
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img alt="" style={{ marginTop: '40px' }} src={quoter} height={100} />
          <Grid style={{ margin: '0px 15px 0px 15px' }}>
            <p className="ownFont">Making software dreams come true</p>
          </Grid>

          <Grid
            container
            spacing={0}
            alignItems='center'
          >
            <Grid item xs={12} sm={6} md={3} className="gridPadding">
              {/* Nuclai start */}
              <Card onClick={() => this.routeTo('https://nuclai.co')}>
                <CardActionArea>
                  <CardMedia
                    className="logoHeight"
                    image={require('./images/nuclai.png')}
                    title="Nuclai"
                    style={{ backgroundSize: '25%' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Nuclai
                </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Artificial intelligence for cancer researchers
                </Typography>
                  </CardContent>
                </CardActionArea>
                <Grid className="alignRight cardGridPadding">
                  <Button size="small" color="inherit">
                    Learn More
                  </Button>
                </Grid>
              </Card>
            </Grid>
            {/* Nuclai start */}

            {/* Temp ninja start */}
            <Grid item xs={12} sm={6} md={3} className="gridPadding">
              <Card onClick={() => this.routeTo('https://temperature.ninja')}>
                <CardActionArea>
                  <CardMedia
                    className="logoHeight"
                    image={require('./images/temperatureninja.png')}
                    title="Temperature ninja"
                    height={this.state.logoHeight}
                    style={{ backgroundSize: '25%' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Temperature Ninja
                </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Learn to seamplessly convert between Celsius and Fahrenheit
                </Typography>
                  </CardContent>
                </CardActionArea>
                <Grid className="alignRight cardGridPadding">
                  <Button size="small" color="inherit">
                    Learn More
                  </Button>
                </Grid>
              </Card>
            </Grid>
            {/* Temp ninja start */}
            {/* CoC start */}
            <Grid item xs={12} sm={6} md={3} className="gridPadding">
              <Card onClick={() => this.routeTo('https://konduct.co')}>
                <CardActionArea>
                  <CardMedia
                    className="logoHeight"
                    image={require('./images/konduct.png')}
                    title="Konduct"
                    height={this.state.logoHeight}
                    style={{ backgroundSize: '29%' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Konduct
                </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Code of conduct made simple
                </Typography>
                  </CardContent>
                </CardActionArea>
                <Grid className="alignRight cardGridPadding">
                  <Button size="small" color="inherit">
                    Learn More
                  </Button>
                </Grid>
              </Card>
            </Grid>
            {/* CoC start */}
            {/* Temp ninja start */}
            <Grid item xs={12} sm={6} md={3} className="gridPadding">
              <Card onClick={() => this.routeTo('http://sunsip.se')}>
                <CardActionArea>
                  <CardMedia
                    className="logoHeight"
                    image={require('./images/sunsip.png')}
                    title="Sunsip"
                    height={this.state.logoHeight}
                    style={{ backgroundSize: '25%' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Sunsip
                </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Find sunny outdoor seating in real-time
                </Typography>
                  </CardContent>
                </CardActionArea>
                <Grid className="alignRight cardGridPadding">
                  <Button size="small" color="inherit">
                    Learn More
                  </Button>
                </Grid>
              </Card>
            </Grid>
            {/* Temp ninja start */}

            {/* Shades start */}
            <Grid item xs={12} sm={6} md={3} className="gridPadding">
              <Card onClick={() => this.routeTo('https://shades-backend.herokuapp.com')}>
                <CardActionArea>
                  <CardMedia
                    className="logoHeight"
                    image={require('./images/shades.png')}
                    title="Shades"
                    height={this.state.logoHeight}
                    style={{ backgroundSize: '53%' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Shades
                </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Artificial intelligence for makeup
                </Typography>
                  </CardContent>
                </CardActionArea>
                <Grid className="alignRight cardGridPadding">
                  <Button size="small" color="inherit">
                    Learn More
                  </Button>
                </Grid>
              </Card>
            </Grid>
            {/* Shades start */}
            {/* Haylabs start */}
            <Grid item xs={12} sm={6} md={3} className="gridPadding">
              <Card onClick={() => this.routeTo('https://haylabs.se')}>
                <CardActionArea>
                  <CardMedia
                    className="logoHeight"
                    image={require('./images/hay.png')}
                    title="Haylabs"
                    height={this.state.logoHeight}
                    style={{ backgroundSize: '25%' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Haylabs
                </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    Keep track of your hay bale consumption
                </Typography>
                  </CardContent>
                </CardActionArea>
                <Grid className="alignRight cardGridPadding">
                  <Button size="small" color="inherit">
                    Learn More
                  </Button>
                </Grid>
              </Card>
            </Grid>
            {/* Haylabs end */}
            {/* Bubbly codes start */}
            <Grid item xs={12} sm={6} md={3} className="gridPadding">
              <Card onClick={() => this.routeTo('https://bubbly.codes')}>
                <CardActionArea>
                  <CardMedia
                    className="logoHeight"
                    image={require('./images/bubblycodes.png')}
                    title="Bubbly Code"
                    height={this.state.logoHeight}
                    style={{ backgroundSize: '25%' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Bubbly Code
                </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Sparkling wine and coding
                </Typography>
                  </CardContent>
                </CardActionArea>
                <Grid className="alignRight cardGridPadding">
                  <Button size="small" color="inherit">
                    Learn More
                  </Button>
                </Grid>
              </Card>
            </Grid>
            {/* Bubbly codes start */}
            {/* Monokeros start */}
            <Grid item xs={12} sm={6} md={3} className="gridPadding">
              <Card onClick={() => this.routeTo('https://monokeros.co/')}>
                <CardActionArea>
                  <CardMedia
                    className="logoHeight"
                    image={require('./images/monokeros.png')}
                    title="Monokeros"
                    height={this.state.logoHeight}
                    style={{ backgroundSize: '72%' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Monokeros
                </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Artificial intelligence for everything
                </Typography>
                  </CardContent>
                </CardActionArea>
                <Grid className="alignRight cardGridPadding">
                  <Button size="small" color="inherit">
                    Learn More
                  </Button>
                </Grid>
              </Card>
            </Grid>
            {/* Monokeros end */}
          </Grid>
        </header>
      </div>
    );
  }
}

export default App;
